import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface BaseFormValidationErrors extends ValidationErrors {
  errorMessage: string;
}


@Component({
  template: ''
})
export class BaseFormComponent implements OnInit, OnDestroy {

  private _typeBehaviorSubject = new BehaviorSubject<string>('text');

  private _errorsBehaviorSubject = new BehaviorSubject<BaseFormValidationErrors>({errorMessage: null});
  private _validationSub: Subscription;

  formControl = new FormControl();
  hasRequiredValidator = false;

  // because the Base form components are used in many other components it can also receive external errors, which are rendered in the same way as internal
  externalErrors$ = new BehaviorSubject<ValidationErrors>({});

  @Input()
  label: string;

  @Input()
  ariaDescription: string;

  @Input()
  set type(value: string) {
    this._typeBehaviorSubject.next(value);
  }

  get type(): string {
    return this._typeBehaviorSubject.value;
  }

  get type$(): Observable<string> {
    return this._typeBehaviorSubject.asObservable();
  }

  @Input()
  align: 'left' | 'right' = 'left';

  get errors$(): Observable<BaseFormValidationErrors> {
    return this._errorsBehaviorSubject.asObservable();
  }

  get errorMessage$(): Observable<string> {
    return this.errors$.pipe(map(obj => obj.errorMessage));
  }


  ngOnInit(): void {

    let oldFingerprint: string;

    this._validationSub = combineLatest([
      this.formControl.valueChanges,
      this.externalErrors$
    ]).subscribe(
        ([_, exErrors]) => {

          const allInternalErrors =
          Object.keys(this.formControl.errors || {})
          .map<string>(key => this.formControl.errors[key]).filter(_ => typeof _ === 'string');

          const allExternalErrors =
          Object.keys(exErrors || {})
          .map<string>(key => exErrors[key]).filter(_ => typeof _ === 'string');

          const allErrors = [...allInternalErrors, ...allExternalErrors];

          const formattedErrors = allErrors.map((error, index) => {
            return index === 0 ? error : error.charAt(0).toLowerCase() + error.slice(1);
          });

          const errorFingerPrint = formattedErrors.sort().join(', ') + '.';

          if (errorFingerPrint !== oldFingerprint) {
            const err: BaseFormValidationErrors = {
              errorMessage: errorFingerPrint,
              ...(this.formControl.errors || {})
            };
            this._errorsBehaviorSubject.next(err);
            oldFingerprint = errorFingerPrint;
          }

        }
      );

    this.formControl?.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this._validationSub?.unsubscribe();
  }

}
